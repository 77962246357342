import styled from 'styled-components';

interface IProps {
  open: boolean;
}

export const StyledMenu = styled.nav<IProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--main-bg);
  transform: ${props => (props.open ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: var(--main-color);
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }
`;

export const SairHolder = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
  text-transform: uppercase;
  padding: 2rem 0;
  font-weight: bold;
  letter-spacing: 0.5rem;
  color: var(--main-color);
  text-decoration: none;
  transition: color 0.3s linear;

  svg {
    margin: 0 20px 0 0;
  }

  background: none;
  border: none;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const Highlight = styled.a`
  height: 50px;
  padding: 0 55px;
  border: 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  font-weight: 600;
  color: var(--main-bg) !important;
  background-color: var(--main-color);
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 38px;
  letter-spacing: 1px !important;

  &.active {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  }
`;
