import React, { useCallback, useEffect, useRef } from 'react';
import { v4 } from 'uuid';
import { HeaderHolder, NavHolder, Highlight } from './styles';

interface Props {
  pathname: string;
}

interface IMenu {
  label: string;
  blank?: boolean;
  url: string;
  active: boolean;
}

interface IHighlight {
  label: string;
  blank?: boolean;
  url: string;
  active: boolean;
}

export const menu = (pathname: string): IMenu[] => [
  {
    label: 'Home',
    url: '/',
    active: pathname === '/',
  },
  {
    label: 'Catálogo',
    blank: true,
    url:
      'https://www.magazineluiza.com.br/lojista/solucaodistribuidoradelivroserev/',
    active: pathname.includes('/catalogo'),
  },
  // {
  //   label: 'Colégios Parceiros',
  //   url: '/colegios-parceiros',
  //   active: pathname.includes('/colegios-parceiros'),
  // },
  {
    label: 'Quem Somos',
    url: '/quem-somos',
    active: pathname.includes('/quem-somos'),
  },
  {
    label: 'Contato',
    url: '/contato',
    active: pathname.includes('/contato'),
  },
];

export const highlight = (pathname: string): IHighlight => {
  return {
    label: 'Listas Escolares',
    url: '/listas-escolares',
    active:
      pathname.includes('/listas-escolares') || pathname.includes('/livros'),
  };
};

const Header2 = (props: Props) => {
  const { pathname } = props;

  const headerRef = useRef<HTMLDivElement>(null);

  const fixedHeader = useCallback(() => {
    const header = headerRef.current;

    if (!header) {
      return;
    }

    const sticky = header.offsetTop;

    if (window.pageYOffset > sticky) {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  }, [headerRef]);

  useEffect(() => {
    window.addEventListener('scroll', fixedHeader);
  }, [fixedHeader]);

  return (
    <>
      <HeaderHolder ref={headerRef}>
        <NavHolder>
          <ul>
            {menu(pathname).map(menuItem => (
              <li key={menuItem.label}>
                <a
                  className={menuItem.active ? 'active' : ''}
                  href={menuItem.url}
                  target={menuItem.blank ? 'blank' : ''}
                >
                  {menuItem.label}
                </a>
              </li>
            ))}
          </ul>
        </NavHolder>
        <Highlight
          className={highlight(pathname).active ? 'active' : ''}
          href={highlight(pathname).url}
        >
          {highlight(pathname).label}
        </Highlight>
      </HeaderHolder>
    </>
  );
};

export default Header2;
