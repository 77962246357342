import styled from 'styled-components';

export const HeaderHolder = styled.div`
  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 769px) {
    display: flex;
  }

  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

  height: 100%;
  width: 100%;

  background: white;

  padding: 0 3%;

  @media (max-width: 1920px) {
    height: 85px;
  }

  @media (max-width: 1366px) {
    height: 70px;
  }

  @media (max-width: 768px) {
    display: none;
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
`;

export const NavHolder = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  height: 100%;
  width: 100%;

  flex: 1 0;

  ul {
    width: 70%;
    list-style: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    li {
      &:first-child {
        margin-left: 0px;
      }

      a {
        color: var(--dark-grey);
        font-size: 18px;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);
      }
      a.active {
        color: var(--main-bg);
        font-weight: 500;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
      }
    }
  }
`;

export const Highlight = styled.a`
  height: 70%;
  padding: 0 55px;
  border: 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  font-weight: 600;
  color: var(--main-bg);
  background-color: var(--main-color);
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 38px;

  &.active {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  }
`;
