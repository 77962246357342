import React, { useCallback, useEffect, useState } from 'react';
import {
  FaPhoneAlt,
  FaWhatsapp,
  FaUserCircle,
  FaShoppingCart,
} from 'react-icons/fa';
import { ImExit } from 'react-icons/im';
import LogoOriginal from 'assets/logo-original.svg';
import { getCartSize } from 'components/Cart';
import api from 'services/api';
import { navigate } from 'gatsby';
import {
  HeaderHolder,
  LogoHolder,
  ItemsHolder,
  ItemHolder,
  SairHolder,
  Divisor,
  CartHolder,
} from './styles';

const Header1 = () => {
  const [size, setSize] = useState<number>(0);

  const signOut = useCallback(() => {
    localStorage.removeItem('@FGLivraria:token');
    localStorage.removeItem('@FGLivraria:user');

    api.defaults.headers.authorization = ``;
    navigate('/');
  }, []);

  const getterCartSizer = useCallback(() => {
    setSize(getCartSize());
  }, []);

  useEffect(() => {
    getterCartSizer();
  }, [getterCartSizer]);

  let user = null;
  if (typeof window !== 'undefined' && window) {
    user = localStorage.getItem('@FGLivraria:user');
  }

  return (
    <>
      <HeaderHolder>
        <LogoHolder>
          <a href="/">
            <img src={LogoOriginal} alt="FG Livraria" />
          </a>
        </LogoHolder>
        <ItemsHolder>
          <ItemHolder
            href="tel:+554130163427"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaPhoneAlt size={20} />
            <span>(41) 3016-3427</span>
          </ItemHolder>
          <Divisor />
          <ItemHolder
            href="https://api.whatsapp.com/send?phone=5541991633300&text=Ol%C3%A1%2C%20FG%20Livraria!%20"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="whatsapp" size={30} />
          </ItemHolder>
          <Divisor />
          <ItemHolder
            className={`conta ${user && 'disabled'}`}
            href={user ? '' : '/minha-conta/'}
            rel="noopener noreferrer"
          >
            <FaUserCircle size={25} />
            {user ? (
              <span>{JSON.parse(user).name}</span>
            ) : (
              <span>Login / Registro</span>
            )}
          </ItemHolder>
          <Divisor />
          {user ? (
            <>
              <SairHolder onClick={() => signOut()}>
                <ImExit className="sair" size={25} />
                <span>Sair</span>
              </SairHolder>
              <Divisor />
            </>
          ) : (
            ''
          )}
          <CartHolder href="/carrinho" rel="noopener noreferrer">
            <FaShoppingCart size={30} />
          </CartHolder>
        </ItemsHolder>
      </HeaderHolder>
    </>
  );
};

export default Header1;
