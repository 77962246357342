import styled from 'styled-components';

export const HeaderHolder = styled.div`
  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 769px) {
    display: flex;
  }
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  height: 100%;
  width: 100%;

  background: var(--main-bg);
  border-bottom: 2px solid var(--main-color);

  padding: 0 3%;

  @media (max-width: 1920px) {
    height: 115px;
  }

  @media (max-width: 1366px) {
    height: 100px;
  }

  @media (max-width: 768px) {
    height: 50px;
  }
`;

export const LogoHolder = styled.div`
  flex: 1 1;
  a {
    font-size: 36px;
    img {
      width: 280px;
    }
  }
`;

export const ItemsHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ItemHolder = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 0 30px;

  color: var(--text-color);

  &:first-child {
    margin-left: 0px;
  }

  svg {
    margin-right: 20px;
  }

  svg.whatsapp {
    margin-right: 0px;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
`;

export const SairHolder = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 0 30px;

  color: var(--text-color);

  svg {
    margin: 0 20px 0 0;
  }

  background: none;
  border: none;
`;

export const Divisor = styled.div`
  height: 30px;
  width: 1px;
  background: var(--main-color);
`;

export const CartHolder = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-left: 30px;

  color: var(--text-color);

  svg {
    color: var(--main-color);
  }

  span {
    margin-top: 5px;
    font-size: 14px;
  }
`;
