/* eslint-disable no-param-reassign */
import { parse } from 'path';
import { SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { ILivroDTO, ISerieDTO } from 'utils/dto';
import { v4 } from 'uuid';

export interface ICartItemDTO {
  id: string;
  cartId?: string;
  livro: ILivroDTO;
  serie_id: string;
  quantity: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface ICartDTO {
  orderId?: string;
  id: string;
  userId?: string;
  items: ICartItemDTO[];
  createdAt: Date;
  updatedAt: Date;
}

export const getCartSize = (): number => {
  const cart = localStorage.getItem('@FGLivraria:cart');

  if (!cart) {
    return 0;
  }

  const parsedCart: ICartDTO = cart && JSON.parse(cart);

  return parsedCart.items.length;
};

export const buildItem = (
  livro: ILivroDTO,
  serie_id: string,
  quantity: number
): ICartItemDTO => {
  const item = {
    id: livro.id,
    livro,
    serie_id,
    quantity,
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  return item;
};

export const createCart = (): ICartDTO | undefined => {
  if (!localStorage.getItem('@FGLivraria:cart')) {
    const userData = localStorage.getItem('@FGLivraria:user');

    const initialCart: ICartDTO = {
      id: v4(),
      userId: userData ? JSON.parse(userData).id : null,
      items: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    localStorage.setItem('@FGLivraria:cart', JSON.stringify(initialCart));

    return initialCart;
  }
  return undefined;
};

export const addItemOnCart = (item: ICartItemDTO, serie: ISerieDTO) => {
  const cart = localStorage.getItem('@FGLivraria:cart');

  if (!cart) {
    createCart();
    addItemOnCart(item, serie);
    return;
  }

  const parsedCart: ICartDTO = cart && JSON.parse(cart);

  // item sendo verificado 1/1 - livro apenas possui uma série e .
  const itemAlreadyOnCart = parsedCart.items.find(
    itemm => itemm.id === item.id
  );

  if (!itemAlreadyOnCart) {
    if (
      parsedCart.items[0] &&
      parsedCart.items[0].livro.serie[0] &&
      item.livro.serie[0] &&
      parsedCart.items[0].livro.serie[0].etapa.colegio.id !==
        item.livro.serie[0].etapa.colegio.id
    ) {
      toast.error(
        '❌ Somente adicione o livro de um único colégio por vez no carrinho.',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }

    item.cartId = parsedCart.id;
    item.serie_id = serie.id;
    parsedCart.items.push(item);
  } else {
    const filteredCart = parsedCart.items.filter(itemm => itemm.id !== item.id);
    parsedCart.items = filteredCart;
    const newRenderer = parsedCart.items.find(
      itemm => itemm.livro.disciplina === itemAlreadyOnCart.livro.disciplina
    );
    if (newRenderer && !newRenderer.livro.renderDisciplina) {
      newRenderer.livro.renderDisciplina = true;
    }
  }

  localStorage.setItem('@FGLivraria:cart', JSON.stringify(parsedCart));
};

export const removeItemFromCart = (item_id: string) => {
  const cart = localStorage.getItem('@FGLivraria:cart');

  if (!cart) {
    return;
  }

  const parsedCart: ICartDTO = cart && JSON.parse(cart);

  // item sendo verificado 1/1 - livro apenas possui uma série e .
  const itemAlreadyOnCart = parsedCart.items.find(
    itemm => itemm.id === item_id
  );

  if (itemAlreadyOnCart) {
    const filteredCart = parsedCart.items.filter(itemm => itemm.id !== item_id);
    parsedCart.items = filteredCart;
    if (parsedCart.items[0] && !parsedCart.items[0].livro.renderDisciplina) {
      parsedCart.items[0].livro.renderDisciplina = true;
    }
    const newRenderer = parsedCart.items.find(
      itemm => itemm.livro.disciplina === itemAlreadyOnCart.livro.disciplina
    );
    if (newRenderer && !newRenderer.livro.renderDisciplina) {
      newRenderer.livro.renderDisciplina = true;
    }
  } else {
    return;
  }
  localStorage.setItem('@FGLivraria:cart', JSON.stringify(parsedCart));
};

export const changeLivroQtd = (
  livro_id: string,
  serie_id: string,
  qtd: number
) => {
  const cart = localStorage.getItem('@FGLivraria:cart');

  if (!cart) {
    return;
  }

  const parsedCart: ICartDTO = cart && JSON.parse(cart);

  const itemAlreadyOnCart = parsedCart.items.find(
    itemm => itemm.livro.id === livro_id && itemm.serie_id === serie_id
  );

  if (itemAlreadyOnCart) {
    itemAlreadyOnCart.quantity = qtd;
  }
  localStorage.setItem('@FGLivraria:cart', JSON.stringify(parsedCart));
};

export const emptyCart = () => {
  const cart = localStorage.getItem('@FGLivraria:cart');

  if (!cart) {
    return;
  }

  const parsedCart: ICartDTO = cart && JSON.parse(cart);

  parsedCart.items = [];

  localStorage.setItem('@FGLivraria:cart', JSON.stringify(parsedCart));
};
