import React from 'react';
import {
  FaInstagram,
  FaFacebook,
  FaRoute,
  FaWhatsapp,
  FaPhoneAlt,
  FaAt,
} from 'react-icons/fa';
import { SiGooglemaps } from 'react-icons/si';
import LogoOriginal from 'assets/logo-original.svg';
import {
  FooterHolder,
  ContentHolder,
  CompanyHolder,
  SitemapHolder,
  DoubtHolder,
  CopyrightHolder,
} from './styles';

const Footer = () => {
  return (
    <FooterHolder>
      <ContentHolder>
        <CompanyHolder>
          <div>
            <img src={LogoOriginal} alt="FG Livraria" />
            <p className="endereco">
              Rua Estados Unidos, 400
              <br />
              Loja 2, Bacacheri
              <br />
              Curitiba, Paraná
            </p>
            <strong>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://g.page/solucaodistribuidoradelivros?share"
              >
                <SiGooglemaps size={15} />
                Mapa
              </a>
              <span>/</span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://goo.gl/maps/f1jXSeUfEGiGTPWp9"
              >
                <FaRoute size={15} />
                Rota
              </a>
            </strong>
          </div>
        </CompanyHolder>
        <SitemapHolder>
          <strong>Mapa do Site:</strong>
          <ul>
            <li>
              <a href="/">Página Inicial</a>
            </li>
            <li>
              <a
                target="blank"
                rel="noopener noreferrer"
                href="https://www.magazineluiza.com.br/lojista/solucaodistribuidoradelivroserev/"
              >
                Catálogo
              </a>
            </li>
            <li>
              <a href="/colegios-parceiros">Colégios Parceiros</a>
            </li>
            <li>
              <a href="/quem-somos">Quem Somos</a>
            </li>
            <li>
              <a href="/contato">Contato</a>
            </li>
            <li>
              <a href="/listas-escolares">Listas Escolares</a>
            </li>
          </ul>
        </SitemapHolder>
        <DoubtHolder>
          <strong>Possuí alguma dúvida? Fale conosco:</strong>
          <a className="contato" href="/contato">
            Entre em contato
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp"
            href="https://api.whatsapp.com/send?phone=5541991633300&text=Ol%C3%A1%2C%20FG%20Livraria!%20"
          >
            <FaWhatsapp size={30} />
            (41) 99163-3300
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="telefone"
            href="tel:+554130163427"
          >
            <FaPhoneAlt size={25} />
            (41) 3016-3427
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="mail"
            href="mailto:contato@fglivraria.com"
          >
            <FaAt size={25} />
            contato@fglivraria.com
          </a>
        </DoubtHolder>
      </ContentHolder>
      <CopyrightHolder>
        <span>
          FG Livraria - Todos os direitos reservados.
          <a href="/">Privacidade.</a>
        </span>
        <span>
          <a
            href="https://www.facebook.com/solucaodistribuidora/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={20} />
          </a>
          <a
            href="https://www.instagram.com/solucao_livros/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={20} />
          </a>
        </span>
      </CopyrightHolder>
    </FooterHolder>
  );
};

export default Footer;
