import React, { SetStateAction } from 'react';
import { StyledBurger } from './burger.styles';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}

const Burger = ({ open, setOpen, ...props }: IProps) => {
  const isExpanded = !!open;

  return (
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <span />
      <span />
      <span />
    </StyledBurger>
  );
};

export default Burger;
