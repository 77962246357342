import styled from 'styled-components';

export const FooterHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  bottom: 0%;
  height: auto;
  width: 100%;

  background: var(--main-bg);
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.3);

  padding: 50px 15% 30px;

  @media (max-width: 1366px) {
    padding: 50px 5% 30px;
  }
  @media (max-width: 768px) {
  }
`;

export const ContentHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: 0 15px 80px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CompanyHolder = styled.div`
  width: 32%;
  border-right: 1px solid #ccc;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 70%;
    img {
      width: 75%;
    }
    p {
      margin: 15px 0 0;
      line-height: 1.7;
      color: #ccc;
    }
    strong {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 600;
      color: #ccc;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      span {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        margin: 0 10px;
        color: var(--main-color);
      }
      a {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        svg {
          margin-right: 5px;
        }

        font-weight: 600;
        color: var(--main-color);
        text-transform: uppercase;
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;

    border-right: none;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;

    div {
      width: 100%;
      align-items: center;
      p {
        text-align: center;
      }
    }
  }
`;

export const SitemapHolder = styled.div`
  width: 25%;
  padding: 0 50px;

  border-right: 1px solid #ccc;

  strong {
    color: #fff;
    font-size: 20px;
    margin-left: -20px;
  }
  ul {
    list-style: circle;
    li {
      color: #ccc;
      margin: 10px 0;
      a {
        color: #ccc;
        text-decoration: none;
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const DoubtHolder = styled.div`
  width: 43%;
  height: 100%;
  flex: 1 1;
  padding: 0 0 0 50px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  strong {
    color: #fff;
    font-size: 20px;
  }
  a {
    font-size: 18px;
    color: #fff;
    margin: 15px 0 0;
  }
  a.contato {
    background-color: var(--main-color);
    padding: 10px 30px;
    border-radius: 30px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 1);
    box-shadow: 0 0 15px rgba(251, 222, 55, 0.5);
  }
  a.whatsapp {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;

    svg {
      width: 50px;
      color: #25d366;
      filter: drop-shadow(0px 0px 5px rgba(37, 211, 102, 0.3));
    }
  }
  a.telefone,
  a.mail {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;

    svg {
      width: 50px;
      color: #fff;
      filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.3));
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 0 20px 0;

    border-right: none;
    align-items: center;

    strong {
      text-align: center;
    }
  }
`;

export const CopyrightHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 15px 15px 0;

  border-top: 1px solid var(--main-color);

  span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #ccc;
    svg {
      margin: 0 5px;
    }
    a {
      margin-left: 5px;
      color: var(--main-color);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    span {
      font-size: 12px;
    }
    span:first-of-type {
      margin-bottom: 10px;
    }
  }
`;
