import React, { useCallback, useEffect, useRef, useState } from 'react';
import LogoOriginal from 'assets/logo-original.svg';
import { FaShoppingCart } from 'react-icons/fa';
import FocusLock from 'react-focus-lock';
import Burger from 'components/Hamburguer/burger';
import Menu from 'components/Hamburguer';
import api from 'services/api';
import { getCartSize } from 'components/Cart';
import { useOnClickOutside } from './util';
import { HeaderHolder, MenuHolder, LogoHolder, CartHolder } from './styles';

interface IProps {
  pathname: string;
}

const MobileHeader = ({ pathname }: IProps) => {
  const [size, setSize] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  const menuId = 'main-menu';

  const getterCartSizer = useCallback(() => {
    setSize(getCartSize());
  }, []);

  useEffect(() => {
    getterCartSizer();
  }, [getterCartSizer]);

  useOnClickOutside(node, () => setOpen(false));

  const headerRef = useRef<HTMLDivElement>(null);

  const fixedHeader = useCallback(() => {
    const header = headerRef.current;

    if (!header) {
      return;
    }

    const sticky = header.offsetTop;

    if (window.pageYOffset > sticky) {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  }, [headerRef]);

  useEffect(() => {
    window.addEventListener('scroll', fixedHeader);
  }, [fixedHeader]);

  return (
    <>
      <HeaderHolder ref={headerRef}>
        <MenuHolder>
          <div ref={node}>
            <FocusLock disabled={!open}>
              <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
              <Menu pathname={pathname} open={open} />
            </FocusLock>
          </div>
        </MenuHolder>
        <LogoHolder>
          <a href="/">
            <img src={LogoOriginal} alt="FG Livraria" />
          </a>
        </LogoHolder>
        <CartHolder href="/carrinho/">
          <FaShoppingCart size={25} />
        </CartHolder>
      </HeaderHolder>
    </>
  );
};

export default MobileHeader;
