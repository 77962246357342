import React, { useCallback } from 'react';
import api from 'services/api';
import { ImExit } from 'react-icons/im';
import { navigate } from 'gatsby';
import { StyledMenu, SairHolder, Highlight } from './styles';

import { menu, highlight } from '../_layout/header2';

interface IProps {
  open: boolean;
  pathname: string;
}

const Menu = ({ open, pathname, ...props }: IProps) => {
  const isHidden = !!open;
  const tabIndex = isHidden ? 0 : -1;

  const signOut = useCallback(() => {
    localStorage.removeItem('@FGLivraria:token');
    localStorage.removeItem('@FGLivraria:user');

    api.defaults.headers.authorization = ``;
    navigate('/');
  }, []);

  let user = null;
  if (typeof window !== 'undefined' && window) {
    user = localStorage.getItem('@FGLivraria:user');
  }

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      {menu(pathname).map(menuItem => (
        <a
          key={menuItem.label}
          href={menuItem.url}
          target={menuItem.blank ? 'blank' : ''}
          tabIndex={tabIndex}
        >
          {menuItem.label}
        </a>
      ))}
      {!user && <a href="/minha-conta">Login / Registro</a>}
      {user ? (
        <>
          <SairHolder onClick={() => signOut()}>
            <ImExit className="sair" size={25} />
            <span>Sair</span>
          </SairHolder>
        </>
      ) : (
        ''
      )}
      <Highlight
        className={highlight(pathname).active ? 'active' : ''}
        href={highlight(pathname).url}
      >
        {highlight(pathname).label}
      </Highlight>
    </StyledMenu>
  );
};

export default Menu;
