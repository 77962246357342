import React, { useState } from 'react';
import { PageProps } from 'gatsby';
import GlobalStyles from 'styles/global';
import { ToastContainer } from 'react-toastify';
import { HeadersHolder, ContentHolder } from './styles';
import Header1 from './header1';
import Header2 from './header2';
import Footer from './footer/index';
import SEO from './seo';
import MobileHeader from './mobile-header';

interface Props extends PageProps {
  children: JSX.Element[] | JSX.Element;
  pathname: string;
}

const Layout = (props: Props) => {
  const { children, pathname } = props;

  return (
    <>
      <GlobalStyles />
      <SEO
        title="FG Livraria - Uma forma descomplicada na compra de listas escolares."
        description="FG Livraria é facilitar a vida dos pais na compra dos materiais didáticos das listas escolares dos seus filhos. Entre em nosso site e confira os colégios parceiros e adquira os livros de seu filho."
        lang="pt-br"
        author="Pedro Henrique Lucho, @pdNN"
      />
      <ToastContainer />
      <HeadersHolder>
        <Header1 />
        <Header2 pathname={pathname} />
        <MobileHeader pathname={pathname} />
      </HeadersHolder>
      <ContentHolder>{children}</ContentHolder>
      <Footer />
    </>
  );
};

export default Layout;
