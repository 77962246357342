import styled from 'styled-components';

export const HeaderHolder = styled.div`
  @media (max-width: 768px) {
    display: flex;
  }

  @media (min-width: 769px) {
    display: none;
  }
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 100px;
  width: 100%;

  background: var(--main-bg);
  border-bottom: 2px solid var(--main-color);

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
`;

export const MenuHolder = styled.div`
  width: 20%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LogoHolder = styled.div`
  width: 60%;
  flex: 1 1;

  display: flex;
  justify-content: center;
  align-items: center;

  a {
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 90%;
    }
  }
`;

export const CartHolder = styled.a`
  width: 20%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: var(--text-color);

  svg {
    color: var(--main-color);
  }

  span {
    margin-top: 5px;
    font-size: 12px;
  }
`;
